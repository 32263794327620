<template>
  <div class="reset-password-view">
    <v-container fill-height>
      <v-layout justify-center fill-height class="reset-password-view__layout">
        <div class="reset-password-view__form-wrapper">
          <div class="reset-password-view__form-header">
            <div class="reset-password-view__form-header-logo-wrapper">
              <Logo large />
            </div>
            <div class="reset-password-view__form-header-title-wrapper">
              <FormTitle transform="capitalize">Change password</FormTitle>
            </div>
          </div>
          <div class="reset-password-view__form-body">
            <ResetPasswordForm
              @submit="submitPasswordForm"
              :password.sync="passwordForm.password"
              :passwordConfirmation.sync="passwordForm.confirmPassword"
            />
          </div>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { updatePasswordByToken } from '@/mixins';
import ResetPasswordForm from '@/components/forms/ResetPasswordForm';
import FormTitle from '@/components/typography/FormTitle';
import Logo from '@/components/Logo';

export default {
  name: 'ResetPassword',
  mixins: [updatePasswordByToken],
  components: {
    ResetPasswordForm,
    FormTitle,
    Logo,
  },
};
</script>

<style lang="scss">
.reset-password-view {
  height: 100%;
}
.reset-password-view__layout {
  padding-top: 120px;
}
.reset-password-view__form-wrapper {
  width: 360px;
}
.reset-password-view__form-header-logo {
  height: 67px;
}
.reset-password-view__form-header-logo-wrapper {
  margin-bottom: 33px;
}
.reset-password-view__form-text {
  margin-bottom: 0px;
  color: $text-semi-dark;
  font-size: 1rem;
  font-weight: 300;
  a {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
  }
}
.reset-password-view__form-body {
  margin-top: 21px;
}
.reset-password-view__form-resend-wrapper {
  margin-top: 16px;
}
.reset-password-view__form-resend-text {
  font-size: 0.6875rem;
  font-weight: 300;
  line-height: 1.36;
  margin-bottom: 1.25rem;
}
</style>
