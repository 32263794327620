<template>
  <h5 :style="`--text-transform: ${transform}`" class="form-title">
    <slot></slot>
  </h5>
</template>

<script>
export default {
  name: 'FormTitle',
  props: {
    transform: {
      type: String,
      default: 'none',
    },
  },
};
</script>

<style lang="scss" scoped>
.form-title {
  color: $text-darkest;
  font-size: 1.5rem;
  line-height: 1.16;
  font-weight: 500;
  text-transform: var(--text-transform);
}
</style>
