<template>
  <v-form ref="form" class="reset-password-form">
    <div class="reset-password-form__content">
      <div class="reset-password-form__fields">
        <v-text-field
          @input="handlePasswordInput"
          :value="password"
          :label="passwordLabel"
          :rules="['required', 'password']"
          type="password"
        />
        <v-text-field
          @input="handlePasswordConfirmationInput"
          :value="passwordConfirmation"
          :label="passwordConfirmationLabel"
          :rules="['required']"
          :errorMessages="passwordMatchErrorMessage"
          type="password"
        />
      </div>
      <div class="reset-password-form__footer">
        <v-btn @click.once="submit" block>
          Save
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import syncForm from '@/mixins/syncForm';
import validateForm from '@/mixins/validateForm';
import isValidString from '@/utils/helpers/string/isValidString';

export default {
  name: 'LoginForm',
  mixins: [syncForm, validateForm],
  data() {
    return {
      submitDisabled: false,
    };
  },
  props: {
    password: String,
    passwordLabel: {
      type: String,
      default: 'Password',
    },
    passwordConfirmation: String,
    passwordConfirmationLabel: {
      type: String,
      default: 'Repeat new password',
    },
  },
  computed: {
    passwordMatchErrorMessage() {
      return isValidString(this.passwordConfirmation) && this.passwordConfirmation !== this.password
        ? "The passwords don't match"
        : '';
    },
  },
  methods: {
    handlePasswordInput(value) {
      const field = 'password';
      this.$emit(`update:${field}`, value);
      this.handleFieldInput(field, value);
    },
    handlePasswordConfirmationInput(value) {
      const field = 'passwordConfirmation';
      this.handleFieldInput(field, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password-form__footer {
  margin-top: 1.125rem;
}
</style>
